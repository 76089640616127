<script lang="ts">
export default {
  name: 'InputSlider',
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineEmits, defineProps, Ref, ref, onMounted, withDefaults, watch } from 'vue';
import { useNoUiSlider } from '@/composables/useNoUiSlider';
import * as nouislider from 'nouislider';

const props = withDefaults(
  defineProps<{ modelValue?: number; min?: number; max?: number; formInput?: string }>(),
  {
    modelValue: 0,
    min: 0,
    max: 5,
  }
);
const emit = defineEmits(['update:model-value']);

const slider: Ref<HTMLElement | null> = ref(null);
const noUiSliderInstance: Ref<nouislider.API | null> = ref(null);
const formInputValue = ref(props.modelValue);

const onSliderInput = (value: (string | number)[]) => {
  if (value?.length) {
    const modelValue = Number(value[0]);
    emit('update:model-value', modelValue);

    if (props.formInput) {
      formInputValue.value = modelValue;
    }
  }
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (noUiSliderInstance.value && noUiSliderInstance.value.get(true) !== newValue) {
      noUiSliderInstance.value.set(newValue);
    }
  }
);

onMounted(() => {
  if (slider.value) {
    noUiSliderInstance.value = useNoUiSlider(slider.value, {
      start: props.modelValue,
      min: props.min,
      max: props.max,
    });

    noUiSliderInstance.value?.on('change', onSliderInput);
  }
});
</script>

<template>
  <slot />
  <div v-bind="$attrs" ref="slider" class="slider-input"></div>
  <input v-if="props.formInput" :name="props.formInput" :value="formInputValue" type="hidden" />
</template>
